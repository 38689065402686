import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import MainCard from '../Card/MainCard';

const Slider = ({ chapters, open, setOpen }) => {
    return (
        <div className='p-3 flex flex-col gap-3 justify-start items-start w-[100vw]'>
            <h2 className='text-[#fff]'>Сүүлд нэмэгдсэн:</h2>
            <Swiper
                spaceBetween={50}
                slidesPerView={window.innerWidth > 720 ? 7 : 3}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                className='self-start w-screen max-md:w-[95vw] max-md:mr-10'
            >
                {chapters.map((e) => (
                    <SwiperSlide>
                        <MainCard {...e} open={open} setOpen={setOpen} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default Slider
