import React from 'react'
import Button from '@mui/joy/Button';
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup';

export default function ToggleGroupVariants() {
    const [value, setValue] = React.useState('manga');
    return (
        <ToggleButtonGroup
            variant={'solid'}
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
        >
            <Button value="manga" sx={{ fontSize: '18px', color: value === 'manga' ? '#fff' : '#838383' }}>
                Манга
            </Button>
            <Button value="manhwa" sx={{ fontSize: '18px', color: value === 'manhwa' ? '#fff' : '#838383' }}>
                Манвуа
            </Button>
        </ToggleButtonGroup>
    );
}