import React, { useState } from 'react'
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { GrFavorite } from "react-icons/gr";
import { Link } from 'react-router-dom';
import { useFavorite } from '../../Context/FavoriteContext';
import { MdOutlineFavorite } from "react-icons/md";

const StyledRating = styled(Rating)({
    '& .MuiRating-iconEmpty': {
        color: '#FFD700',
    },
    '& .MuiRating-iconFilled': {
        color: '#FFD700'
    },
});

const RowCard = ({ productName, rating, cover, description, _id }) => {
    const { favs, addFavorite, removeFromProduct } = useFavorite();

    const addToFavorite = () => {
        const savedData = {
            name: productName,
            rating: rating,
            cover: cover,
            description: description,
            id: _id,
        }

        addFavorite(savedData);
    }

    const removeProduct = () => {
        removeFromProduct(_id);
    }

    return (
        <div className='p-3 pl-5 flex items-center w-[max-content] max-md:w-[95vw]'>
            <img className='w-[12vw] h-[17vw] rounded-sm max-md:w-[30vw] max-md:h-[45vw]'
                src={'https://shuriken.templateapi.xyz/uploads/' + cover} alt="" />
            <div className='p-5 min-h-[13vw] bg-black flex flex-col max-md:w-[60vw] items-start text-[#fff] gap-5 
            rounded-md max-md:h-[max-content]'>
                <h2 className='font-semibold'>{productName}</h2>
                <h2 className='text-[13px] font-extralight max-md:flex max-md:flex-wrap max-md:w-[55vw]'>{description}</h2>
                <div className='flex items-center gap-3'>
                    <Stack spacing={2} >
                        <StyledRating name="half-rating" defaultValue={rating} precision={0.5} readOnly />
                    </Stack>
                    <p className='text-[15px] font-extralight'>{rating}</p>

                </div>
                <div className=' bg-[#A9B0B9] custom-hover px-4 py-[1px] border-[#C0C0C0] border text-center rounded-xl text-[#333333]'>
                    <Link to={'/product/' + _id}>
                        Унших
                    </Link>
                </div>

                {
                    favs && favs.filter((e) => e.id === _id).length > 0 ?
                        <button onClick={removeProduct}>
                            <MdOutlineFavorite size={20} color='red' />
                        </button>
                        :
                        <button onClick={addToFavorite} >
                            <GrFavorite size={20} color='#fff' />
                        </button>
                }
            </div>
        </div>
    )
}

export default RowCard
