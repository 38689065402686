import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import SuggestionSwiperCard from '../Card/SuggestionSwiperCard';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import styles from './index.module.css'

export default function SuggestionSwiper() {
    return (
        <>
            <Swiper
                pagination={true}
                className={styles.mySwiper}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}
            >
                <SwiperSlide>
                    <SuggestionSwiperCard />
                </SwiperSlide>
                <SwiperSlide>
                    <SuggestionSwiperCard />
                </SwiperSlide>
            </Swiper>
        </>
    );
}
