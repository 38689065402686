import * as React from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function CustomSnackbar({ open, setOpen }) {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Snackbar style={{ top: 0, position: 'absolute', right: 0 }} open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
                <Alert
                    style={{
                        top: 0, position: 'absolute', left: 0, width: window.innerWidth > 720
                            ? (window.innerWidth * 0.5) : window.innerWidth * 0.8, marginTop: '70px'
                    }}
                    onClose={() => setOpen(false)}
                    severity="warning"
                    variant="filled"
                >
                    Та сунгалтаа хийнэ үү!
                </Alert>
            </Snackbar>
        </Box>
    );
}
