import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import BottomNavigationBar from './Component/BottomNavigation/BottomNavigation';
import Navbar from './Component/Navbar/Navbar';
import Categories from './Pages/Categoies/Categories';
import Favorite from './Pages/Favorite/Favorite';
import ProductDetial from './Pages/ProductDetial/ProductDetial';
import Login from './Pages/Login/Login';
import Profile from './Pages/Profile/Profile';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Register from './Pages/Login/Register';
import { FavoriteProvider } from './Context/FavoriteContext';
import ChapterRead from './Pages/ChapterRead/ChapterRead';

function App() {
  const [chapters, setChapters] = useState([]);
  const [categories, setCategoirs] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const userCache = JSON.parse(window.localStorage.getItem('suser'));

  console.log(userCache);

  const url = 'https://shuriken.templateapi.xyz';

  useEffect(() => {
    if (isLoading) {
      Promise.all([
        axios.get(url + '/chapters'),
        axios.get(url + '/categories'),
        axios.get(url + '/product'),
      ]).then(([chap, cat, prod]) => {
        setCategoirs(cat.data.data);
        setChapters(chap.data.data);
        setProducts(prod.data.data);
      })
        .finally(() => setIsLoading(false));
    }

    if (userCache) {
      axios.get(url + '/user/' + userCache._id)
        .then((e) => {
          // alert(e.daysLeft);
          console.log(e);
          window.localStorage.setItem('suser', JSON.stringify(e.data.data));
        })
    }
  }, [isLoading]);

  if (isLoading) {
    return <div className="loader-container">
      <div className="loader-bg">
        <div className="loader"></div>
        <div className="loader-circle"></div>
      </div>
    </div>
  }

  return (
    <FavoriteProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home products={products} categories={categories} chapters={chapters} />} />
          <Route path='/categories' element={<Categories products={products} />} />
          <Route path='/favorite' element={<Favorite />} />
          <Route path='/product/:id' element={<ProductDetial chapters={chapters} products={products} />} />
          <Route path='/login' element={<Login />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/register' element={<Register />} />
          <Route path='/chapter/:id' element={<ChapterRead chapters={chapters} />} />
        </Routes>
        <BottomNavigationBar />
      </Router>
    </FavoriteProvider>
  );
}

export default App;