import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import CustomSnackbar from '../../Utills/Snackbar';

const MainCard = ({ cover, title, productName, _id, open, setOpen }) => {
    const user = JSON.parse(window.localStorage.getItem('suser'));

    return (
        <>
            {user ? parseInt(user.daysleft) < 0 ?
                <button onClick={() => { setOpen(true) }}>
                    <div className='cursor-pointer custom-hover p-1 w-[max-content]'>
                        <img className='w-[12vw] h-[16vw] rounded-sm max-md:w-[30vw] max-md:h-[45vw]'
                            src={'https://shuriken.templateapi.xyz/uploads/' + cover} alt="" />
                        <h2 className='text-[18px] font-semibold text-[#fff]'>{productName}</h2>
                        <p className='text-[14px] text-[#F78500]'>{title}</p>
                    </div>
                </button>
                :
                <Link to={title ? '/chapter/' + _id : '/product/' + _id}>
                    <div className='cursor-pointer custom-hover p-1 w-[max-content]'>
                        <img className='w-[12vw] h-[16vw] rounded-sm max-md:w-[30vw] max-md:h-[45vw]'
                            src={'https://shuriken.templateapi.xyz/uploads/' + cover} alt="" />
                        <h2 className='text-[18px] font-semibold text-[#fff]'>{productName}</h2>
                        <p className='text-[14px] text-[#F78500]'>{title}</p>
                    </div>
                </Link> :
                <button onClick={() => { setOpen(true) }}>
                    <div className='cursor-pointer custom-hover p-1 w-[max-content]'>
                        <img className='w-[12vw] h-[16vw] rounded-sm max-md:w-[30vw] max-md:h-[45vw]'
                            src={'https://shuriken.templateapi.xyz/uploads/' + cover} alt="" />
                        <h2 className='text-[18px] font-semibold text-[#fff]'>{productName}</h2>
                        <p className='text-[14px] text-[#F78500]'>{title}</p>
                    </div>
                    {/* <CustomSnackbar open={open} setOpen={setOpen} /> */}
                </button>
            }
        </>
    )
}

export default MainCard
