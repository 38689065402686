import React, { createContext, useContext, useState, useEffect } from "react";

// 
const FavoriteContext = createContext();


export const FavoriteProvider = ({ children }) => {
    const [favs, setFavs] = useState(() => {
        const savedFavs = JSON.parse(localStorage.getItem("fav-sh"));
        return savedFavs || [];
    });

    useEffect(() => {
        const saveFavs = JSON.parse(localStorage.getItem("fav-sh"));
        // console.log(saveFavs);
        setFavs(saveFavs);

    }, []);


    useEffect(() => {
        localStorage.setItem("fav-sh", JSON.stringify(favs));
    }, [favs]);


    const addFavorite = (newCard) => {
        setFavs((prevCart) => {
            return [...(prevCart || []), newCard];
        });
    };

    const removeFromProduct = (id) => {
        setFavs((prevCart) => prevCart.filter((item) => item.id !== id));
    };
    return (
        <FavoriteContext.Provider value={{ favs, addFavorite, removeFromProduct }}>
            {children}
        </FavoriteContext.Provider>
    );
};


export const useFavorite = () => {
    return useContext(FavoriteContext);
};
