import React from 'react'

const BorderBox = ({ icon, text }) => {
    return (

        <div className='border rounded-md border-[#A4E4FF] p-5'>
            <div className='bg-[#A4E4FF] rounded-full bg-opacity-20 p-3 w-[50px] h-[50px] flex items-center justify-center'>
                {icon}
            </div>
            <h2 className='text-[#A4E4FF] text-[20px]'>{text}</h2>
        </div>
    )
}

export default BorderBox
