import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import logo from '../../assets/logo.svg'

export default function Navbar() {
    return (
        <Box sx={{ flexGrow: 1 }} >
            <AppBar position="static"
                sx={{
                    bgcolor: '#1c1b1b',
                    '&.Mui-selected': {
                        color: '#A4E4FF',
                    }
                }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <img src={logo} color='#fff' className='w-[50px]' alt="" />
                    </IconButton>
                    <Typography variant="h8" component="div" sx={{ flexGrow: 1, color: '#fff' }}>
                        Нүүр хуудас
                    </Typography>

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <SearchIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
