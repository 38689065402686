import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList } from 'react-window';
import { Link, useParams } from 'react-router-dom';
import { useFavorite } from '../../Context/FavoriteContext';

function renderRow(props) {
    const { index, style, data } = props;

    return (
        <ListItem style={style} key={index} component="div" disablePadding>
            <Link to={'/chapter/' + data[index]._id}>
                <ListItemButton>
                    <ListItemText sx={{ color: '#A4E4FF' }} primary={`${data[index].title}`} />
                </ListItemButton>
            </Link>
        </ListItem>
    );
}

const ProductDetial = ({ chapters, products }) => {
    // const [saved, setSaved] = useState(false);
    const [product, setProduct] = useState({});
    const [productChapters, setProductChapters] = useState([]);
    const { id } = useParams();
    const { favs, addFavorite, removeFromProduct
    } = useFavorite();

    useEffect(() => {
        setProduct(products.find((e) => e._id === id));
        setProductChapters(chapters.filter((e) => e.product === id));

    }, [products, chapters, id]);

    return (
        <div className='flex flex-col items-center justify-center p-5 gap-10'>
            <img className='w-[15vw] h-[25vw] rounded-sm max-md:w-[40vw] max-md:h-[60vw]'
                src={"https://shuriken.templateapi.xyz/uploads/" + product.cover} alt="" />
            <div className='flex flex-col bg-black w-[60vw] p-5 rounded-t-2xl gap-10 max-md:w-[90vw]'>
                <p className={`text-[#A4E4FF]`}>Үргэлжилж байгаа</p>
                <div className='flex items-center justify-between'>
                    <div>
                        <h2 className='text-[#fff] text-[25px] font-semibold'>
                            {product.productName}
                        </h2>
                        <p className='text-[#838383] text-[14px] font-extralight'>Manhwa</p>
                    </div>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                            if (favs) {
                                const isFavorite = favs.some((e) => e.id === product._id);
                                if (!isFavorite) {
                                    addFavorite({
                                        name: product.productName,
                                        rating: product.rating,
                                        description: product.description,
                                        cover: product.cover,
                                        id: product._id
                                    });
                                } else {
                                    removeFromProduct(product._id);
                                }
                            } else {

                                addFavorite({
                                    name: product.productName,
                                    rating: product.rating,
                                    description: product.description,
                                    cover: product.cover,
                                    id: product._id
                                });
                            }
                        }}
                    >
                        {favs.some((e) => e.id === product._id) ? (
                            <BookmarkIcon
                                sx={{
                                    fontSize: '30px',
                                    color: '#A4E4FF',
                                    '&.Mui-selected': {
                                        color: '#A4E4FF',
                                    },
                                }}
                            />
                        ) : (
                            <BookmarkBorderIcon
                                sx={{
                                    fontSize: '30px',
                                    color: '#A4E4FF',
                                    '&.Mui-selected': {
                                        color: '#A4E4FF',
                                    },
                                }}
                            />
                        )}
                    </IconButton>

                </div>
                <div className='bg-[#979797] flex flex-wrap justify-between px-10 py-3 rounded-md text-[#fff] font-semibold'>
                    <div className='flex flex-col items-center justify-center'>
                        <p >Үнэлгээ</p>
                        <p>{product.rating}</p>
                    </div>
                    <div className='border-r-[1px] border-[#fff]'></div>
                    <div className='flex flex-col items-center justify-center'>
                        <p>Нийт бүлэг</p>
                        <p>{chapters.length}</p>
                    </div>
                    <div className='border-r-[1px] border-[#fff]'></div>
                    <div className='flex flex-col items-center justify-center'>
                        <p>Хэл</p>
                        <p>Монгол</p>
                    </div>
                </div>
                <p className='text-[#f0f0f0] text-[15px] font-extralight' >
                    {product.description}
                </p>
                <div className='flex flex-col gap-3'>
                    <h2 className='text-[20px] text-[#fff] font-semibold'>Бүлгүүд</h2>
                    <Box
                        sx={{ width: '100%', height: 400, maxWidth: '100%', bgcolor: '#333', borderRadius: '15px' }}
                    >
                        <FixedSizeList
                            height={400}
                            width={'100%'}
                            itemSize={46}
                            itemCount={productChapters.length}
                            overscanCount={5}
                            itemData={productChapters}
                        >
                            {renderRow}
                        </FixedSizeList>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default ProductDetial
