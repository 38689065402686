import React from 'react'
import RowCard from '../../Component/Card/RowCard'
import { useFavorite } from '../../Context/FavoriteContext'

const Favorite = () => {
    const { favs } = useFavorite();
    return (
        <div className='flex flex-wrap items-center justify-center min-h-screen'>
            {
                favs ? favs.length > 0 ? favs.map((e) => (
                    <RowCard productName={e.name} _id={e.id} {...e} />
                )) : <p className='text-[#fff] z-50'>Хоосон байна</p>
                    : <p className='text-[#fff] z-50'>Хоосон байна</p>
            }
        </div>
    )
}

export default Favorite
