import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const ChapterRead = ({ chapters }) => {
    const { id } = useParams();
    const [chapter, setChapter] = useState({});

    useEffect(() => {
        setChapter(chapters.find((e) => e._id === id));
    }, [chapters, id]);

    if (!chapter.chapters) {
        return <div className="loader-container">
            <div className="loader-bg">
                <div className="loader"></div>
                <div className="loader-circle"></div>
            </div>
        </div>
    }

    return (
        <div className='flex flex-col justify-center items-center py-10'>
            <p className='text-[20px] text-[#fff]'>{chapter.title}</p>
            <br />
            {chapter.chapters.map((e) => (
                <div>
                    <img className='w-[30vw] max-md:w-[90vw]'
                        src={"https://shuriken.templateapi.xyz/uploads/" + e.chapter} alt="" />
                </div>
            ))}
        </div>
    )
}

export default ChapterRead
