import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import styles from './BottomNavigation.module.css';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function BottomNavigatioBar() {
    const [value, setValue] = React.useState('recents');
    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={styles.bottombar}>
            <BottomNavigation
                sx={{ width: '100vw' }}
                style={{ backgroundColor: '#1C1B1B' }}
                value={value}
                onChange={handleChange}
            >
                <BottomNavigationAction
                    label="Нүүр"
                    value="recents"
                    onClick={() => navigate('/')}
                    icon={<HomeIcon />}
                    sx={{
                        color: value === 'recents' ? '#333' : '#FFF',
                        '&.Mui-selected': {
                            color: '#A4E4FF',
                        }
                    }}
                />
                <BottomNavigationAction
                    label="Ангилал"
                    value="nearby"
                    icon={<AutoStoriesIcon />}
                    onClick={() => navigate('/categories')}
                    sx={{
                        color: value === 'nearby' ? '#A4E4FF' : '#FFF',
                        '&.Mui-selected': {
                            color: '#A4E4FF',
                        }
                    }}
                />
                <BottomNavigationAction
                    label="Хадгалсан"
                    value="favorites"
                    icon={<FavoriteIcon />}
                    onClick={() => navigate('/favorite')}
                    sx={{
                        color: value === 'favorites' ? '#A4E4FF' : '#FFF',
                        '&.Mui-selected': {
                            color: '#A4E4FF',
                        }
                    }}
                />
                <BottomNavigationAction
                    label="Миний хэсэг"
                    value="folder"
                    icon={<AccountCircleIcon />}
                    onClick={() => navigate('/login')}
                    sx={{
                        color: value === 'folder' ? '#A4E4FF' : '#FFF',
                        '&.Mui-selected': {
                            color: '#A4E4FF',
                        }
                    }}
                />
            </BottomNavigation>
        </div>
    );
}
